<template>
  <div>

    <search-product-items @submit="submitSearch" />

    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showSortModal" v-if="isShowMenu('menu:tenant_settings')">排序</a-button>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >

      <template slot="images" slot-scope="imgs, record">
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="deletePackageItem(record.id)" class="custom-delete">删除</a>
          <a v-if="record.sell_out === false" @click="sellOut(record.id)">售罄</a>
          <a v-else @click="online(record.id)">恢复</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增项目模态框 -->
    <new-product-item
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      :product-id="this.productId"
      @completed="fetchData"
    />

    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />

    <sort-product-specification
      v-if="isShowSortModal"
      :visible.sync="isShowSortModal"
      :data="sortData"
      table-name="product_specifications"
      :columns="sortColumns"
      @completed="fetchSortData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchProductItems from '@/views/products/products_specifications/Search'
import PreviewImage from '@/components/PreviewImage'
import { findProductSpecificationList, deleteProductSpecification, findProductSpecificationSortList, sellOutProductSpecification, onlineProductSpecification } from '@/api/product_specification'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'ProductSpecificationList',
  components: {
    Pagination,
    PreviewImage,
    SearchProductItems,
    SortProductSpecification: () => import('@/components/Sort/productSpecification.vue'),
    NewProductItem: () => import('@/views/products/products_specifications/New')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      previewVisible: false,
      previewImages: [],
      isShowSortModal: false, // 是否显示排序弹窗
      sortData: [], // 排序弹窗页面数据
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: '规格名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '价格',
          dataIndex: 'str_price'
        },
        {
          title: '图片',
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '操作',
          width: 120,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    sortColumns() {
      return [
        {
          title: '规格名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '操作',
          width: 120,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    productId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    sellOut(recordId) {
      this.sellOutPackageItem(recordId)
    },
    online(recordId) {
      this.onlinePackageItem(recordId)
    },
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    showSortModal() {
      findProductSpecificationSortList({ product_id: this.productId }).then((res) => {
        this.sortData = res.data
        this.isShowSortModal = true
      })
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchSortData() {
      this.query = {}
      // 重置排序
      this.sort = this.$options.data().sort
      this.loading = true
      findProductSpecificationList({ product_id: this.productId }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    fetchData() {
      this.loading = true
      findProductSpecificationList(
        Object.assign({}, this.query, { product_id: this.productId })
      ).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    deletePackageItem(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定进行删除吗？删除后无法恢复',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteProductSpecification(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    },
    sellOutPackageItem(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定售罄吗？售罄后对应规格产品将无法订购',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          sellOutProductSpecification(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    },
    onlinePackageItem(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定恢复吗？恢复后对应规格产品将可以进行订购',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          onlineProductSpecification(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    }
  }
}
</script>
